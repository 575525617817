.full_bg_labportal{background: #fff;}
.right_partlap{float: right; padding: 15px; background: #fff; height: 100%;}
.padding-top20{margin-top: 24px;}
.logo_box{margin-top:11px;border-bottom: 1px solid #f1f1f1;padding-bottom: 26px;overflow: hidden;}
.logo_labportal{width: 200px; height: auto; float: left; cursor: pointer; margin-left: 5px;}

.labportal_menu{ background: #fff; position: fixed; height: 100%;border-right: 1px solid #f1f1f1;}
.mobile_navicon{display:none!important;}
.labportal_heading{font-weight: bolder; padding: 20px 16px 0px 10px; text-align: right;}




.portaltablebg .sticky{top: 70px; background: #fff;}

.portaltablebg tr th{background: #66cc33;}
.dashboard-left-nav-2 .left-nav-items-lab li.labactive{background: #66cc33; color:#fff;}
.dashboard-left-nav-2 .left-nav-items-lab li.labUnactive{ color:#86c44b !important;}
.dashboard-left-nav-2 .left-nav-items-lab li:hover{background: #86c44b; color:#fff !important;}
.labbgcolor{overflow: hidden;
  border: 2px solid #66cc33;
  width: 35px;
  height: 35px;
  line-height: 28px;}
.reset_butt{background: #66cc33!important;padding:7px!important;min-width:30px!important;}
.reset_butt:hover{background: #86c44b!important;}

.iconcolorgreen svg{color: rgb(102 204 51)!important;}

.labtabledetails tr th{background: #66cc33; color:#fff;}
.icon_pdf svg{color: rgb(102 204 51)!important;}





.dropdown-menu_lap {
  min-width: 13rem;
    top: 15px !important;
    left: -2px !important;
    overflow: revert !important;
    border: none!important;
    background-color: #66cc33!important;
    padding: 3px!important;
}
.left-nav-items-2{border-top:none!important;margin-top:6px;margin-top:25px;}
.hover-row:hover{box-shadow:none!important;}
.menu_new{padding-right: 0px!important; border-bottom: 1px solid #f1f1f1 !important;}
.menu_new span{ font-size: 15px !important;}
.menu_sub span{ font-size: 15px !important;}
.text_right{text-align: right; padding-right: 0px;}
.p_left0{padding-left: 0px; padding-right: 0px;}
.dashboard-headerlab {
  background-color: #f6f7fb;
  height: 70px; display: none;
  padding:0px !important;
  justify-content: space-between;
  border-bottom: 1px solid #f6f7fb;
  padding-left:0px !important;
}
/* .header_leftpartlab{float: left; background: #fff;width: 15%;border-right:1px solid #f1f1f1;} */
/* .header_rightpartlab{float: right; background: #fff;width: 85%;} */
.headdingtop{float: left; margin-left: 20px;}
.dashboard-header .logo-imglab {  
  margin-left: 50px; 
}
.dashboard-userlab{position: absolute; bottom: 0px; left:10px;}
.listmenulab {padding-top:0px!important; padding-bottom:0px!important;}

.main_tablelab{width:100%!important;}
.details_popup{width:100%!important;float:none!important;padding-left:0px;}
.details_popupwidth .css-1t1j96h-MuiPaper-root-MuiDialog-paper{max-width: 700px !important;}
.main-data-table tr td{padding: 7px 9px!important;}
.detail-action {
  margin-top: 5px!important;
}

.icon_pdf{float:right;margin-top:2px;}
.pdf-collapse.large{height: 500px !important;}
.userlab{ overflow: hidden;}
  
.MuiDataGrid-root .MuiDataGrid-columnsContainer{background: #eceff6;}
  
  
  .button-up::after,
  .button-down::after {
	content: "";
	position: absolute;
	left: 17px;
	z-index: 11;
	display: block;
	width: 25px;
	height: 25px;
	border-top: 2px solid #fff;
	border-left: 2px solid #fff;
  }
  .button-up::after {
	top: 20px;
	transform: rotate(45deg);
  }
  
  .button-down::after {
	top: 10px;
	transform: rotate(225deg);
  }
  
  .arrow-right,
  .arrow-left,
  .long-arrow-right,
  .long-arrow-left{
	display: block;
	margin: 30px auto;
	width: 25px;
	height: 25px;
	border-top: 2px solid #e4e4e4;
	border-left: 2px solid #e4e4e4;
  }
  .arrow-right,
  .long-arrow-right{
   transform: rotate(135deg);
  }
  
  .arrow-left,
  .long-arrow-left{
   transform: rotate(-45deg);
  }
  .long-arrow-right::after,
  .long-arrow-left::after{
	content: "";
	display: block;
	width: 2px;
	height: 45px;
	background-color: #e4e4e4;
	transform: rotate(-45deg) translate(15px, 4px);
	left: 0;
	top: 0;
  }
  
  .triangle-top
  {
	width: 0;
	height: 0;
	margin:0px auto;
  }
  
  .triangle-top
  {
	border-left:
	  8px solid transparent;
	border-right:
	  8px solid transparent;
	
	
  }
  
  
  .triangle-top{
    display: block;    
    border-color: transparent;
    border-top-color: #66cc33;
    border-style: solid;
    border-width: 10px 10px 0;   
    position: absolute;    
    bottom: -10px;
    left: 10px;
  }
.value_select{
  margin-bottom:none !important; 
  text-overflow: ellipsis;
  overflow: hidden;
  width: 145px;
  line-height: 20px;
  white-space: nowrap;
}

.value_cont p{margin-top: 0;
  margin-bottom:none !important; 
  cursor: pointer;
  text-overflow: ellipsis;
  overflow: hidden;
  width: 130px; margin-bottom: 0px !important;
  line-height: 20px;
  white-space: nowrap;}

  .tablerepo_box{width: 101.4%; margin: 0px; clear: both;}
  .value_cont{ padding:6px 6px !important;border-bottom: none !important;}
  .repoting_table{background-color: #466579 !important; padding: 10px 6px !important;}



  .header-menu li {
    padding: 5px;
    white-space: nowrap;
    background: #fff;
    /* border-bottom: none; */
}



.data-table-filters {
    height: 100%;
    display: flex;
    max-height:none; padding-bottom: 20px;
    justify-content: flex-end; border-bottom: 1px solid #f1f1f1;
}

.data-table-filters .lapportal_search {
	max-width: 100%!important;
    border:none;
    border-radius: 30px;
    display: flex;
    background-color: #f1f1f1;
    height: 45px;
    max-width: inherit; margin-top: 4px;
    border-top: none;
    border-right: none;
    border-left: none;

}
.data-table-filters .lapportal_search input {
  background: #f1f1f1;
	padding: 0px 10px 0px 5px;
    max-width:100%!important;
    border-radius:30px;
    max-width: inherit;
}

.data-table-filters .search-div .fa-search{color:#66cc33;margin:8px 3px 0px 11px;}

.data-table-filters-patients {
  height: 90%;
  display: flex;
  max-height:none; 
  padding-bottom: 17px;
  padding-left: 20px;
  justify-content: flex-end; 
  /* border-bottom: 1px solid #f1f1f1; */
}

.data-table-filters-patients .lapportal_search {
max-width: 100%!important;
  border:none;
  border-radius: 30px;
  display: flex;
  background-color: #f1f1f1;
  height: 45px;
  max-width: inherit; margin-top: 4px;
  border-top: none;
  border-right: none;
  border-left: none;

}
.data-table-filters-patients .lapportal_search input {
background: #f1f1f1;
padding: 0px 10px 0px 5px;
  max-width:100%!important;
  border-radius:30px;
  max-width: inherit;
}

.data-table-filters-patients .search-div .fa-search{margin:8px 3px 0px 11px;}




.mainpage_repoting{width: 100%!important;
  overflow-x: hidden!important;
  height: auto !important;  
  position: sticky !important;
  margin-bottom: 50px;}

.sticky_notes{position: sticky !important;
    background-color: #ffffff;
    z-index: 0;
    white-space: nowrap;}
.filter_bg{background: #fff; padding-bottom: 16px;}
.repoting_page{padding-bottom:0px!important;}

.repotingpageleftnav{ padding-right:15px;}
.logoripoting{border-bottom: 1px solid #f1f1f1;padding-bottom:17px;margin-bottom:17px;}
.headding_repoting{width: 20%;
  float: left;
  text-align: left;}
.table_repotingnew{width: 80%;
  float: right;
  text-align: right;}


  .filter_repoting{    border-bottom: 1px solid #f1f1f1; padding: 10px 0px 15px 0px!important; margin-right: -5px;
    display: flex;;}

   .details_form{ margin: 100px 0px 20px 80px;} 
   .listdetailsrepoting{margin:40px 0px 0px 0px !important;}
  
   .tablerepo_box table tr th span{ margin-left: -6px;}

   .sticky_table_th .sticky{position: sticky !important;
    background-color: #ffffff;
    z-index: 0;
    white-space: nowrap;}
